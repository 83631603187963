@import "bulma/css/bulma.min.css";
@import "icons-css-ion/dist/ionicons.css";

.loader-wrapper {
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  opacity: 0;
  z-index: -1;
  transition: opacity 0.3s;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 6px;
}

.loader {
  height: 80px;
  width: 80px;
}

.loader-wrapper,
.is-active {
  opacity: 1;
  z-index: 1;
}


.dropdown-content {
  background-color: #fff;
  border-radius: 1px;
  box-shadow: 0 .5em 1em -.125em rgba(10,10,10,.1),0 0 0 1px rgba(10,10,10,.02);
  padding-bottom: .1rem;
  padding-top: .1rem;
}
